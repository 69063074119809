.modal-header {
  align-items: unset;
  padding: 0 1rem;
}

.modal-footer {
  padding: 10px 0 0 0;
  justify-content: space-between;
}

.modal-footer .row button:nth-child(1) {
  margin-right: 5px;
}

.card {
  box-shadow: none;
}

.accordion .card-body {
  font-size: 13px;
  text-transform: uppercase;
}

.accordion .badge-danger {
  font-size: 12px;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.download-certificate {
  padding: 0 0 10px 0;
  font-size: 14px;
}

.download-certificate:link,
.download-certificate:visited,
.download-certificate:hover,
.download-certificate:focus,
.download-certificate:active {
  color: #007bff;
}

.btn-accordion {
  border: none;
  padding: 0;
  margin: 0;
  color: #000000;
}

.btn-accordion:link,
.btn-accordion:visited,
.btn-accordion:hover,
.btn-accordion:focus,
.btn-accordion:active {
  color: #000000;
  text-decoration: none;
}

.reject-box {
  margin-top: 10px;
}

.close.btn-secondary {
  color: #000;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: inline;
}

.nav > li,
.nav > li > a {
  position: relative;
  display: block;
}

.sidebar .nav li > a {
  margin: 5px 15px;
  border-radius: 4px;
}

.sidebar .nav li.active > a {
  color: #fff;
  opacity: 1;
  background: hsla(0, 0%, 100%, 0.23);
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar .navbar-brand {
  text-transform: capitalize;
}

.nav > li > a {
  padding: 10px 15px;
}

.alert-list {
  width: 40px;
  height: 20px;
  display: block;
}

/* -------- */
/* TOOTLTOP */
/* -------- */

.tootltip {
  color: #000000;
  z-index: 999999;
}

.tootltip h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

.tootltip .content {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: -10px;
  left: 15px;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  color: #000000;
  width: 250px;
  margin-bottom: 10px;
}

.tootltip:after {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: "";
  height: 15px;
  left: 15px;
  position: absolute;
  top: -10px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 15px;
}

.tootltip .content .btn-routes {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.tootltip .content .btn-routes a,
a:active {
  color: #427fed;
}

.tootltip .content .btn-routes a:hover {
  text-decoration: underline;
}

.tootltip .content .info {
  padding: 10px 0;
}

.tootltip .content p {
  margin: 0;
  padding: 0;
  font-size: 13px;
}

/* ------- */
/* FILTERS */
/* ------- */

.searchBox::placeholder {
  color: #e3e3e3;
}

.content-filters {
  display: flex;
  justify-content: space-between;
}

.content-filters select {
  color: #e3e3e3;
}
.content-filters select option {
  color: black;
}

.content-filters select option:first-child {
  color: #e3e3e3;
}

/* .content-filters div {
  width: 200px;
} */

.content-filters #multiselectContainerReact div:first-child {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 4px 12px;
  height: 40px;
  box-shadow: none;
}

/* --------------- */
/* CHECK LIST TAKE */
/* --------------- */

.modalChecklist #multiselectContainerReact div:first-child {
  display: flex;
}

/* ----------------------- */
/* GOOGLE MAPS MULTISELECT */
/* ----------------------- */

.google-maps-container {
  height: 90vh;
  width: 100%;
  position: relative;
}

.google-maps-container .address-selection {
  position: absolute;
  z-index: 9999;
  margin: 10px 0 0 10px;
  background-color: #fff;
}

.google-maps-container .address-selection:first-child {
  width: 406px;
}

.google-maps-container .address-selection #multiselectContainerReact input {
  font-size: 14px;
  width: 100%;
}

.google-maps-container
  .address-selection
  #multiselectContainerReact
  div:first-child {
  border: none;
}

.google-maps-container
  .address-selection
  #multiselectContainerReact
  .optionListContainer {
  font-size: 12px;
  margin-top: 5px;
}

.google-maps-container
  .address-selection
  #multiselectContainerReact
  .optionListContainer
  .optionContainer {
  border-radius: unset;
  border: none;
}

.optionListContainer .notFound {
  display: none;
}

/* ------------------- */
/* GOOGLE MAPS MARKERS */
/* ------------------- */

.google-cluster-point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 77px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-position: center;
  background-image: url("https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png");
  background-repeat: no-repeat;
}

.google-single-point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 43px;
  background-image: url("https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2.png");
}

/* -------------- */
/* PLATE VALIDATE */
/* -------------- */

.content-plate-validation,
.content-dashboard-information {
  display: flex;
}

.content-dashboard-agent {
  display: inline-block;
}

.content-plate-validation .plate-information {
  width: 60%;
  margin-right: 20px;
}

.content-plate-validation .plate-penalties {
  width: 40%;
}

.content-search-input {
  display: flex;
  align-items: center;
}

.content-information {
  display: flex;
  padding-bottom: 10px;
}

.extradata-information {
  margin-bottom: 20px;
}

.content-search-input div:first-child {
  margin-right: 3px;
}

.content-search-input input {
  height: 33px;
}

/* ------------ */
/* REQUEST DATE */
/* ------------ */

.react-datepicker-wrapper {
  width: 100%;
}

@media (max-width: 991px) {
  .google-maps-container .address-selection {
    position: relative;
    margin: 10px;
    width: 100%;
  }
  .google-maps-container .address-selection:first-child {
    width: auto;
  }

  .google-maps-container
    .address-selection
    #multiselectContainerReact
    div:first-child {
    border: solid 1px #cccccc;
  }
}

/* ----- */
/* LOGIN */
/* ----- */

.container-fluid .row:first-child {
  display: flex;
  justify-content: flex-start;
}

.main-panel-login {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.login {
  display: flex;
  justify-content: center !important;
}

.login .logo {
  text-align: center;
  margin: 0 0 5px 0;
  font-size: x-large;
  text-transform: uppercase;
}

.login .content {
  margin: 0 0 20px 0;
  font-size: 18px;
  font-weight: bold;
}

/* ---------- */
/* NAVIGATION */
/* ---------- */

.sidebar-wrapper .nav a.dropdown-item:link,
.sidebar-wrapper .nav a.dropdown-item:visited,
.sidebar-wrapper .nav a.dropdown-item:hover,
.sidebar-wrapper .nav a.dropdown-item:focus,
.sidebar-wrapper .nav a.dropdown-item:active {
  color: #fff;
}

.sidebar-wrapper .nav a.dropdown > i {
  font-size: 70px;
}

/* -------------------- */
/* MODAL CHECKLIST TOMA */
/* -------------------- */

.modalChecklist {
  font-size: 15px;
}

/* ------------------- */

.formCheckbox {
  margin-left: 5px;
}

.modal-title {
  font-size: 16px;
  line-height: normal;
}

/* --------------- */
/* TABLA PRINCIPAL */
/* --------------- */

.mainlist > tbody > tr > td {
  font-size: 12px;
  padding: 5px 8px;
}

.mainlist > thead > tr > th {
  text-transform: capitalize;
}

.capitalize {
  text-transform: capitalize;
}

span.badge {
  margin: 0px 2px;
}

span.plate_number {
  font-size: larger;
  font-weight: bold;
}

div.col > label {
  margin-top: 5px;
}

img.picThumb {
  width: 200px;
  border: #565656 1px solid;
  margin: 5px;
}

.badgePrimary {
  background-color: #0d6efd;
}

.badgeCreated {
  background-color: #25a30b;
}
.badgeRejected {
  color: #fff;
  background-color: #e81e1e;
}
.badgePendingValidation {
  background-color: #999999;
}
.badgeValidationException {
  color: #000000;
  background-color: #e4eb17;
}
.badgeAccept {
  background-color: #06c94e;
}
.badgeSigningForm08 {
  color: #fff;
  background-color: #054aed;
}
.badgeFolioInProgress {
  background-color: #05daed;
}
.badgeFolioInProgressStandBy {
  color: black;
  background-color: #d0d612;
}
.badgeDelivered {
  color: #fff;
  background-color: #aa53e0;
}
.badgeDeliveryRejected {
  background-color: #d43599;
}
.badgePresentationWaiting {
  background-color: #808080;
}
.badgePresentationReceptionCat {
  color: #fff;
  background-color: #0800ff;
}
.badgePresentationDelivered {
  color: #000000;
  background-color: #ffff00;
}
.badgePresentationPresented {
  color: #000000;
  background-color: #ffc0cb;
}
.badgePresentationRetired {
  color: #fff;
  background-color: #008000;
}
.badgePresentationObserved {
  color: #fff;
  background-color: #ff0000;
}
.badgePresentationObservedDelivered {
  background-color: #ffa500;
}
.badgePresentationObservedPresented {
  background-color: #bd00ff;
}
.badgeCompleted {
  color: #fff;
  background-color: #000;
}
.badgePendingDocWithdraw {
  color: #000;
  background-color: #ff8800;
}
.badgePendingDocDelivered {
  color: #000;
  background-color: #ff8800;
}
.badgePendingDocReceived {
  color: #000;
  background-color: #ff8800;
}

.badgeWarning {
  color: #000;
  background-color: #ffc107;
}

.badgeDark {
  color: #fff;
  background-color: #343a40;
}

.bold {
  font-weight: bold;
}

.react-datepicker__month-container {
  min-height: 270px;
}

.content-filters div span {
  /* font-size: 20px;
  display: flex; */
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.content-dashboard .information {
  width: 65%;
  margin-right: 20px;
}

.content-dashboard .agent {
  width: 320px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}

.content-dashboard .list-cases {
  width: 50%;
}

.list-cases + .list-cases {
  margin-left: 20px;
}

.content-dashboard-information div {
  margin-bottom: 10px;
}

.content-dashboard-information .deadline {
  width: 100%;
  margin-top: 20px;
}

/* Table header sort */
.sort-by {
  cursor: pointer;
}
.sort-by-block {
  cursor: no-drop;
}
.sort-by-none::after {
  font-family: "Font Awesome 6 Free";
  content: "\f0dc";
  display: inline-block;
  margin-left: 5px;
  font-weight: 900;
}
.sort-by-asc::after {
  font-family: "Font Awesome 6 Free";
  content: "\f0de";
  display: inline-block;
  margin-left: 5px;
  font-weight: 900;
}
.sort-by-desc::after {
  font-family: "Font Awesome 6 Free";
  content: "\f0dd";
  display: inline-block;
  margin-left: 5px;
  font-weight: 900;
}

.hidden {
  display: none;
}

.objetive {
  color: blue;
  padding-left: 10px;
}

.text-violet {
  color: violet;
}

.text-yellow {
  color: yellow;
}
