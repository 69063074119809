.export {
  text-align: end;
  margin-bottom: 10px;
}

.generalExport {
  text-align: end;
  margin-bottom: 10px;
  margin-right: 15px;
}

.lessHeight {
  height: 43px;
}