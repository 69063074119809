.containerSidebar {
  > [class~="sidebar"] {
    width: 100px !important;
  }
  > div > [class~="sidebar-wrapper"] {
    width: 100px !important;
  }
  + [class~="main-panel"]{
    width: calc(100% - 100px);
  }
   > div > [class~="logo"]{
     padding: 10px 20px;
   }
}


.icons {
  display: flex !important;
}

