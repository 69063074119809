.checkbox {
  display: flex;
  margin-top: 10px;
  > label {
    width: 78%;
  }
}

.checkLabel {
  width: 5px;
}

.checkText {
  margin-left: 13px;
}

.checkBoxColumns {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.navLink {
  margin-top: -8px;
}
